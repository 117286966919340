export default {
  BUTTON_PRIMARY_CLASS: 'cm_button btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'cm_button btn btn--secondary',
  LOAD_MORE_BUTTON_CLASS: 'cm_button btn btn--primary',
  SEARCH_BOX_BUTTON_CLASS: 'cm_button btn btn--primary',

  GARAGE_SIZE:
    '<span class="garage-size header__cart-count absolute bg-theme-text text-theme-bg text-center" key="garage-size">{{size}}</span>',
  GARAGE_TITLE: '',
};
