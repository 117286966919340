//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-792:_3765,_4932,_4108,_8596,_7268,_7972,_7908,_6768;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-792')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-792', "_3765,_4932,_4108,_8596,_7268,_7972,_7908,_6768");
        }
      }catch (ex) {
        console.error(ex);
      }